import { styled } from "@mui/material/styles";
import {
  Stack,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import BannerBg from "assets/images/banner.png";
import BannerBgM from "assets/images/banner-m.png";
import Tvl from "./Tvl";
import Typewriter from "typewriter-effect";
import Transitions from "components/Transitions";
import { useShow } from "hooks/useShow";
const BanBox = styled(Stack)`
  width: 100%;
  height: 100vh;
  background: #000;
  position: relative;
  overflow: hidden;
  min-height: 1000px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      height: "850px",
      minHeight: "auto",
      paddingBottom: "80px",
      overflow: "visible",
    },
  })};

  @media screen and (min-width: 900px) and (max-width: 1199px) {
    align-items: flex-start;
  }
`;

const BannerBox = styled("div")`
  height: 732px;
  width: 1366px;
  background: url(${BannerBg}) no-repeat;
  background-position: right center;
  background-size: cover;
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      height: "100%",
      background: `url(${BannerBgM}) no-repeat`,
      backgroundPosition: "bottom center",
      backgroundSize: "100% auto",
      width: "100%",
    },
  })};
  @media screen and (min-width: 2000px) {
    width: 80%;
    height: 100%;
  }
  @media screen and (min-width: 475px) and (max-width: 900px) {
    background-position: center;
  }
  @media screen and (min-width: 900px) and (max-width: 1199px) {
    height: 100vh;
    background: url(${BannerBgM}) no-repeat;
    background-position: 80% center;
  }
`;

const Containter = styled(Stack)`
  height: 732px;
  width: 1366px;
  padding: 0 120px;
  position: relative;
  z-index: 2;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
      padding: "0 5px",
    },
  })};
  @media screen and (min-width: 2000px) {
    width: 70%;
  }
`;

const PcBox = styled(Stack)`
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      padding: "100px 20px 60px 20px",
    },
  })};
`;

const TextBox = styled(Box)`
  width: 720px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  })};
  @media screen and (min-width: 2000px) {
    width: 30vw;
  }
`;

const BannerButton = styled(Button)`
  color: #fff;
  background: #4c67ef;
  padding: 20px 75px;
  height: 68px;
  &:hover {
    color: #fff;
    background: #4c67ef;
  }
  &.Mui-disabled {
    color: #5d5d5d;
    background-color: #3f3f3f;
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "45px",
      fontSize: "14px",
      padding: "15px 30px",
    },
  })};
`;

const HeadPc = styled(Typography)`
  font-size: 70px;
  font-family: Arboria-Bold;
  font-weight: 300;
  line-height: 60px;
  background-image: linear-gradient(
    to right,
    #fff,
    #fff,
    #fff,
    rgba(43, 71, 217, 0.8),
    rgba(43, 71, 217, 0.8)
  );
  background-clip: text;
  color: transparent;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "36px",
      lineHeight: "36px",
    },
  })};
  @media screen and (min-width: 2000px) {
    font-size: 3.5vw;
    line-height: 1;
  }
`;

const DescPc = styled(Typography)`
  color: #fff;
  font-family: Arboria-Book;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.55;
  max-width: 420px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  })};
  @media screen and (min-width: 2000px) {
    font-size: 1vw;
  }
`;

const TvlBox = styled("div")`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 98;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      padding: "20px",
      bottom: '0',
    },
  })};
`;

const Banner = () => {
  const theme = useTheme();
  const upToMd = useMediaQuery(theme.breakpoints.up("md"));
  const { ref, isShow } = useShow();
  return (
    <BanBox
      ref={ref}
      justifyContent="center"
      alignItems="center"
      direction="row"
    >
      <Containter>
        <PcBox
          justifyContent={{ xs: "space-between", md: "center" }}
          alignItems={"statr"}
          direction={"column"}
          height={"100%"}
        >
          <TextBox>
            <Transitions isUp={false} delay={300} isShow={isShow}>
              <Box>
                <HeadPc>The First</HeadPc>
                <HeadPc mt={10}>
                  <Typewriter
                    options={{
                      loop: true,
                      delay: 25,
                      deleteSpeed: 25,
                    }}
                    onInit={(typewriter) => {
                      typewriter
                        .typeString("Decentralized")
                        .pauseFor(1000)
                        .deleteChars(13)
                        .pauseFor(500)
                        .typeString("Resilient")
                        .pauseFor(1000)
                        .deleteChars(9)
                        .pauseFor(500)
                        .typeString("Integrated")
                        .pauseFor(1000)
                        .deleteChars(10)
                        .pauseFor(500)
                        .start();
                    }}
                  />
                </HeadPc>
                <HeadPc mt={10}>BTC Restaking Network</HeadPc>
              </Box>
            </Transitions>
            <Transitions delay={500} isShow={isShow}>
              <Box>
                <DescPc mt={20}>
                  Scale your BTC yield with secure restaking.
                </DescPc>
              </Box>
            </Transitions>
          </TextBox>
          <Box mt={50}>
            <Transitions delay={800} isShow={isShow}>
              <a href="https://app.pell.network" target="_blank">
                <BannerButton variant="contained">Restake Now</BannerButton>
              </a>
            </Transitions>
          </Box>
        </PcBox>
        <BannerBox></BannerBox>
      </Containter>
      <TvlBox>
        <Tvl />
      </TvlBox>
    </BanBox>
  );
};

export default Banner;
