import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const Operators: React.FC<IconProps> = ({
  width = "37px",
  height = "35px",
}) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" style={{ width, height }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="37"
        height="35"
        viewBox="0 0 37 35"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M31.6891 25.0796C32.3355 25.322 32.663 26.0425 32.4206 26.6889C31.2358 29.8482 26.5149 35.2749 17.0813 33.9885C16.3972 33.8952 15.9183 33.2651 16.0116 32.5811C16.1049 31.8971 16.735 31.4182 17.419 31.5114C25.5854 32.625 29.2645 27.9851 30.0797 25.8111C30.3221 25.1647 31.0427 24.8372 31.6891 25.0796Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.46662 4.46662C10.3266 1.60669 14.2055 -3.57628e-07 18.25 0C22.2946 3.57628e-07 26.1735 1.60669 29.0334 4.46662C31.8933 7.32655 33.5 11.2054 33.5 15.25H31C31 11.8685 29.6567 8.62548 27.2656 6.23439C24.8745 3.8433 21.6315 2.5 18.25 2.5C14.8685 2.5 11.6255 3.8433 9.23439 6.23439C6.8433 8.62548 5.5 11.8685 5.5 15.25H3C3 11.2054 4.60669 7.32655 7.46662 4.46662Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 17.25C0 15.4551 1.45507 14 3.25 14H8.5V27.5H3.25C1.45507 27.5 0 26.0449 0 24.25V17.25ZM3.25 16.5C2.83579 16.5 2.5 16.8358 2.5 17.25V24.25C2.5 24.6642 2.83579 25 3.25 25H6V16.5H3.25Z"
          fill="#7A8FFF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.5 24.25C36.5 26.0449 35.0449 27.5 33.25 27.5L28 27.5L28 14L33.25 14C35.0449 14 36.5 15.4551 36.5 17.25L36.5 24.25ZM33.25 25C33.6642 25 34 24.6642 34 24.25L34 17.25C34 16.8358 33.6642 16.5 33.25 16.5L30.5 16.5L30.5 25L33.25 25Z"
          fill="#7A8FFF"
        />
      </svg>
    </SvgIcon>
  );
};

export default Operators;
