import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import { ReactElement } from "react";
import Fade from "@mui/material/Fade";

const FadeUp = styled(Fade)`
  @keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  &.fade-in-up {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
`;

const Transitions = ({
  children,
  isShow,
  delay = 1000,
  isUp = true,
}: {
  children: ReactElement;
  isShow: boolean;
  delay?: number;
  isUp?: boolean;
}) => {
  return (
    <FadeUp
      className={isShow && isUp ? "fade-in-up" : ""}
      in={isShow}
      style={{
        transitionDelay: isShow ? `${delay}ms` : "0ms",
        transitionDuration: isShow ? "600ms" : "0ms",
      }}
    >
      {children}
    </FadeUp>
  );
};

export default Transitions;
