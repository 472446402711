import { styled } from "@mui/material/styles";
import { Stack, Typography, Box, Button } from "@mui/material";
import aboutImg from "assets/images/v2/about-eco.png";
import { Head, SubHead } from "./Style";
import numBg from "assets/images/v2/num-bg.svg";
import { NavLink } from "react-router-dom";
import { useShow } from "hooks/useShow";
import Transitions from "components/Transitions";

const Wapper = styled(Stack)`
  /* background: #151515; */
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  padding: 130px 25px 100px;
  box-sizing: border-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "40px 20px 60px",
    },
  })};
`;

const ContentBox = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: 120px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
      flexDirection: "column",
      paddingBottom: "50px",
      alignItems: "center",
    },
  })};
`;

const LeftBox = styled(Stack)`
  margin-right: 30px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginRight: "0px",
    },
  })};
`;

const ImgCon = styled("img")`
  width: 100%;
  height: auto;
  margin-top: 40px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "auto",
    },
  })};
`;

const AboutListBox = styled(Stack)`
  margin-bottom: 40px;
`;

const NumBox = styled(Stack)`
  width: 44px;
  height: 56px;
  background: url(${numBg}) no-repeat bottom center;
  background-size: contain;
`;

const NumText = styled(Typography)`
  color: #fff;
  font-family: Arboria-BookItalic;
  font-size: 46px;
  font-weight: 400;
`;

const AboutText = styled(Typography)`
  color: #fff;
  font-family: Arboria-Book;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  })};
`;

const LineBox = styled(Box)`
  width: 100%;
  height: 1px;
  border-bottom: 1px dotted #fff;
  margin: 0 0 50px 0;
`;

const AboutPellEcosystem = () => {
  const { ref, isShow } = useShow();
  const aboutList = [
    "Restakers build a decentralized trust market through staking BTC LSD, providing shared security and forming the foundation of the Pell Network.",
    "Actively Validated Services (AVSs) use restaked BTC LSD to superpower their functionality.",
    "Blockchain ecosystem layer dApps benefit from new modular services delivered by AVSs.",
    "Operators perform validation tasks upon which AVSs rely.",
  ];
  return (
    <Wapper justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Stack justifyContent="center" alignItems="center" direction="row">
          <Transitions delay={200} isShow={isShow}>
            <Head variant="h1">About Pell Ecosystem</Head>
          </Transitions>
        </Stack>
        <Transitions isUp={false} delay={400} isShow={isShow}>
          <ContentBox ref={ref}>
            <LeftBox flex={1}>
              {aboutList.map((item, index) => {
                return (
                  <AboutListBox
                    key={index}
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <NumBox
                      justifyContent="center"
                      alignItems="center"
                      direction="row"
                    >
                      <NumText>{index + 1}</NumText>
                    </NumBox>
                    <Box flex={1} ml={12}>
                      <AboutText mt={5}>{item}</AboutText>
                    </Box>
                  </AboutListBox>
                );
              })}
              <LineBox />
              <AboutText>
                Discover and explore cutting-edge web services, networks,
                protocols, and applications being built within the Pell
                ecosystem
              </AboutText>
              <Box mt={20}>
                <NavLink to={"/ecosystem"}>
                  <Button variant={"outlined"}>Explore ecosystem</Button>
                </NavLink>
              </Box>
              <AboutText mt={30}>
                Do you need programmable trust in your distributed system?
              </AboutText>
              <Box mt={20}>
                <NavLink target={"_blank"} to={"https://forms.gle/yFd2Ys78HniFGEX76"}>
                  <Button variant={"outlined"}>Build on Pell Network</Button>
                </NavLink>
              </Box>
            </LeftBox>
            <Stack
              justifyContent="center"
              alignItems={"flex-start"}
              direction="row"
              flex={1}
            >
              <ImgCon src={aboutImg} alt="About Pell Ecosystem" />
            </Stack>
          </ContentBox>
        </Transitions>
      </Containter>
    </Wapper>
  );
};

export default AboutPellEcosystem;
