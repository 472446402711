import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const Stakers: React.FC<IconProps> = ({ width = "40px", height = "40px" }) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" style={{ width, height }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M19.6032 22.5019L3.71885 13.8601C3.16143 13.5566 3.16143 12.7562 3.71885 12.4531L19.6032 3.81132C19.7207 3.74749 19.8523 3.71405 19.986 3.71405C20.1198 3.71405 20.2513 3.74749 20.3688 3.81132L36.2528 12.4531C36.8103 12.7566 36.8103 13.557 36.2528 13.8601L20.3685 22.5019C20.2511 22.566 20.1195 22.5995 19.9858 22.5995C19.8521 22.5995 19.7206 22.566 19.6032 22.5019ZM7.65674 13.1566L19.9856 19.864L32.3146 13.1566L19.9856 6.44921L7.65674 13.1566Z"
          fill="white"
        />
        <path
          d="M35.9857 18.0906L19.9834 26.7969L3.98379 18.0926C3.84863 18.0192 3.68418 18.116 3.68301 18.2696L3.66309 20.652C3.66309 20.7211 3.70098 20.7844 3.76152 20.8172L19.6021 29.4352C19.7191 29.4988 19.8502 29.5322 19.9834 29.5322C20.1166 29.5322 20.2477 29.4988 20.3646 29.4352L36.1084 20.8696C36.1543 20.8445 36.1926 20.8076 36.2193 20.7626C36.2459 20.7177 36.26 20.6664 36.26 20.6141V18.2539C36.26 18.1129 36.1092 18.0235 35.9857 18.0906Z"
          fill="#7A8FFF"
        />
        <path
          d="M36.0207 24.832L19.9598 33.5699L3.94414 24.8566C3.81875 24.7883 3.66602 24.8793 3.66602 25.0219V27.4043C3.66604 27.4487 3.67802 27.4923 3.7007 27.5306C3.72338 27.5688 3.75593 27.6002 3.79492 27.6215L19.5785 36.2086C19.6955 36.2722 19.8266 36.3056 19.9598 36.3056C20.093 36.3056 20.224 36.2722 20.341 36.2086L36.141 27.6125C36.177 27.5929 36.207 27.564 36.2279 27.5288C36.2487 27.4936 36.2598 27.4534 36.2598 27.4125L36.2574 24.9723C36.2574 24.8512 36.1273 24.7738 36.0207 24.832Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default Stakers;
