import { styled } from "@mui/material/styles";
import { Stack, Typography, Avatar, Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import bnbIcon from "assets/images/networks/bsc.svg";
import b2 from "assets/images/networks/b2.svg";
import bitlayer from "assets/images/networks/bitlayer.svg";
import bouncebit from "assets/images/networks/bouncebit.png";
import merlin from "assets/images/networks/merlin.svg";
import Tips from "components/Tips";
import BigNumber from "bignumber.js";
import { getStakeList } from "utils/api";
import { amountFormat } from "utils/format";
import { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useShow } from "hooks/useShow";
import Transitions from "components/Transitions";

const Containter = styled(Stack)`
  width: 1200px;
  position: relative;
  padding: 130px 25px 60px;
  box-sizing: border-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "200px 20px 60px",
    },
  })};
  @media screen and (min-width: 2000px) {
    /* width: 80%; */
  }
`;

const Head = styled(Typography)`
  color: #fff;
  font-family: Arboria-Medium;
  font-size: 64px;
  font-weight: 400;
  text-align: center;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "36px",
    },
  })};
`;

const SubHead = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Arboria-Book;
  font-size: 16px;
  font-weight: 400;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  })};
`;

const ChainBox = styled(Stack)`
  margin-top: 120px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginTop: "36px",
    },
  })};
`;

const ChainItem = styled(Stack)`
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(40px);
  padding: 20px;
  min-height: 430px;
`;

const ChainIcon = styled(Avatar)`
  width: 66px;
  height: 66px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
`;

const ChainHead = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Arboria-Bold;
  font-size: 24px;
  font-weight: 400;
`;

const ChainSubHead = styled(Typography)`
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: Arboria-Book;
  font-size: 16px;
  font-weight: 400;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  })};
`;

const ChainButtonOut = styled(Button)`
  height: 54px;
  padding: 0 10px;
`;
const ChainButtonCon = styled(Button)`
  height: 54px;
  padding: 0 10px;
`;

const Networks = () => {
  const testEev = process.env.REACT_APP_ENABLE_TESTNETS === "true";
  const bnbChain = testEev ? 97 : 56;
  const b2Chain = testEev ? 1123 : 223;
  const [bnbBoost, setBnbBoost] = useState(1);
  const [bnbAmount, setBnbAmount] = useState(0);
  const [b2Boost, setB2Boost] = useState(1);
  const [b2Amount, setB2Amount] = useState(0);
  const { ref, isShow } = useShow();
  // const { ref, inView } = useInView({});
  // const [isShow, setIsShow] = useState(false);

  const initTvl = async () => {
    try {
      let bnbB = 1;
      let bnbA = 0;
      let b2B = 1;
      let b2A = 0;
      let tvlRes = await getStakeList();
      if (tvlRes.result && tvlRes.result.length > 0) {
        const Bnblist = tvlRes.result.filter(
          (item) => item?.chainId === bnbChain
        );
        if (Bnblist.length > 0) {
          Bnblist.forEach((item) => {
            if (item?.baseBoost && Number(item?.baseBoost) > bnbB) {
              bnbB = Number(item?.baseBoost);
            }
            if (item?.tvl) {
              bnbA = new BigNumber(bnbA).plus(item?.tvl).toNumber();
            }
          });
        }
        const B2list = tvlRes.result.filter(
          (item) => item?.chainId === b2Chain
        );
        if (B2list.length > 0) {
          B2list.forEach((item) => {
            if (item?.baseBoost && Number(item?.baseBoost) > b2B) {
              b2B = Number(item?.baseBoost);
            }
            if (item?.tvl) {
              b2A = new BigNumber(b2A).plus(item?.tvl).toNumber();
            }
          });
        }
      }
      setBnbBoost(bnbB);
      setBnbAmount(bnbA);
      setB2Boost(b2B);
      setB2Amount(b2A);
    } catch (error) {
      console.log(error);
    }
  };
  const init = useCallback(async () => {
    await initTvl();
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <Stack justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Stack justifyContent="center" alignItems="center" direction="row">
          <Transitions delay={400} isShow={isShow}>
            <Head>Supported Networks</Head>
          </Transitions>
        </Stack>
        <Stack
          mt={20}
          ref={ref}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Transitions delay={600} isShow={isShow}>
            <SubHead>Pell lets you restake tokens from many networks.</SubHead>
          </Transitions>
        </Stack>
        <Stack justifyContent="center" alignItems="center" direction="row">
          <Transitions delay={600} isShow={isShow}>
            <SubHead>Choose a network below to get started.</SubHead>
          </Transitions>
        </Stack>
        <ChainBox>
          <Transitions isUp={false} delay={800} isShow={isShow}>
            <Grid container spacing={30}>
              <Grid xs={12} md={4}>
                <ChainItem>
                  <Stack
                    mt={20}
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                  >
                    <ChainIcon src={bnbIcon} />
                  </Stack>
                  <Stack
                    mt={20}
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                  >
                    <ChainHead>BNB Smart Chain</ChainHead>
                  </Stack>
                  <Stack
                    mt={10}
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                  >
                    <ChainSubHead>
                      Stake any amount of BTCB, get daily staking rewards and
                      strategies yield.
                    </ChainSubHead>
                  </Stack>
                  <Grid mt={44} container spacing={0}>
                    <Grid xs={6} md={6}>
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                        style={{ borderRight: "1px solid #7c7c7c" }}
                      >
                        <Box>
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            direction="row"
                          >
                            <ChainHead fontSize={20}>{bnbBoost}X</ChainHead>
                          </Stack>
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            direction="row"
                            mt={8}
                          >
                            <ChainSubHead mr={4}>Pell Points</ChainSubHead>
                            <Tips
                              title={`The basic multiplier of getting Pell Points.`}
                            />
                          </Stack>
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid xs={6} md={6}>
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                      >
                        <Box>
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            direction="row"
                          >
                            <ChainHead fontSize={20}>
                              $ {amountFormat(bnbAmount, 0)}
                            </ChainHead>
                          </Stack>
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            direction="row"
                            mt={8}
                          >
                            <ChainSubHead>Staked</ChainSubHead>
                          </Stack>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid mt={30} container spacing={20}>
                    <Grid xs={6} md={6}>
                      <NavLink
                        to={"https://docs.pell.network"}
                        target={"_blank"}
                      >
                        <ChainButtonOut fullWidth variant={"outlined"}>
                          Learn more
                        </ChainButtonOut>
                      </NavLink>
                    </Grid>
                    <Grid xs={6} md={6}>
                      <NavLink
                        to={"https://app.pell.network/restake?chain=56"}
                        target={"_blank"}
                      >
                        <ChainButtonCon fullWidth variant={"contained"}>
                          Stake now
                        </ChainButtonCon>
                      </NavLink>
                    </Grid>
                  </Grid>
                </ChainItem>
              </Grid>
              <Grid xs={12} md={4}>
                <ChainItem>
                  <Stack
                    mt={20}
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                  >
                    <ChainIcon src={b2} />
                  </Stack>
                  <Stack
                    mt={20}
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                  >
                    <ChainHead>B² Network</ChainHead>
                  </Stack>
                  <Stack
                    mt={10}
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                  >
                    <ChainSubHead>
                      Stake any amount of uBTC、cBTC, get daily staking rewards
                    </ChainSubHead>
                  </Stack>
                  <Grid mt={44} container spacing={0}>
                    <Grid xs={6} md={6}>
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                        style={{ borderRight: "1px solid #7c7c7c" }}
                      >
                        <Box>
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            direction="row"
                          >
                            <ChainHead fontSize={20}>{b2Boost}X</ChainHead>
                          </Stack>
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            direction="row"
                            mt={8}
                          >
                            <ChainSubHead mr={4}>Pell Points</ChainSubHead>
                            <Tips
                              title={`The basic multiplier of getting Pell Points.`}
                            />
                          </Stack>
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid xs={6} md={6}>
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                      >
                        <Box>
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            direction="row"
                          >
                            <ChainHead fontSize={20}>
                              $ {amountFormat(b2Amount, 0)}
                            </ChainHead>
                          </Stack>
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            direction="row"
                            mt={8}
                          >
                            <ChainSubHead>Staked</ChainSubHead>
                          </Stack>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid mt={30} container spacing={20}>
                    <Grid xs={6} md={6}>
                      <NavLink
                        to={"https://docs.pell.network"}
                        target={"_blank"}
                      >
                        <ChainButtonOut fullWidth variant={"outlined"}>
                          Learn more
                        </ChainButtonOut>
                      </NavLink>
                    </Grid>
                    <Grid xs={6} md={6}>
                      <NavLink
                        to={"https://app.pell.network/restake?chain=223"}
                        target={"_blank"}
                      >
                        <ChainButtonCon fullWidth variant={"contained"}>
                          Stake now
                        </ChainButtonCon>
                      </NavLink>
                    </Grid>
                  </Grid>
                </ChainItem>
              </Grid>
              <Grid xs={12} md={4}>
                <ChainItem>
                  <Stack
                    mt={20}
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                  >
                    <Box>
                      <ChainIcon
                        style={{ border: "1px solid #fff" }}
                        src={bitlayer}
                      />
                    </Box>
                    <Box ml={-10}>
                      <ChainIcon
                        style={{ border: "1px solid #fff" }}
                        src={bouncebit}
                      />
                    </Box>
                    <Box ml={-10}>
                      <ChainIcon
                        style={{ border: "1px solid #fff" }}
                        src={merlin}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    mt={20}
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                  >
                    <ChainHead>More blockchains</ChainHead>
                  </Stack>
                  <Stack
                    mt={10}
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                  >
                    <ChainSubHead>
                      Pell now supports multiple blockchain networks beyond EVM
                      and non-EVM.
                    </ChainSubHead>
                  </Stack>
                  <Box height={68} mt={44}></Box>
                  <Grid mt={30} container spacing={20}>
                    <Grid xs={6} md={6}>
                      <NavLink
                        to={"https://docs.pell.network"}
                        target={"_blank"}
                      >
                        <ChainButtonOut fullWidth variant={"outlined"}>
                          Learn more
                        </ChainButtonOut>
                      </NavLink>
                    </Grid>
                    <Grid xs={6} md={6}>
                      <NavLink
                        to={"https://app.pell.network/restake"}
                        target={"_blank"}
                      >
                        <ChainButtonCon fullWidth variant={"contained"}>
                          Stake now
                        </ChainButtonCon>
                      </NavLink>
                    </Grid>
                  </Grid>
                </ChainItem>
              </Grid>
            </Grid>
          </Transitions>
        </ChainBox>
      </Containter>
    </Stack>
  );
};

export default Networks;
