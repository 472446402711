import React, { ReactNode } from "react";
// import Tooltip from "@mui/material/Tooltip";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Stack } from "@mui/material";
import { Tootip } from "components/Svg";

const Title = function (props: { children: ReactNode }) {
  return (
    <div
      style={{
        color: "#000",
        fontFamily: "Arboria-Book",
        fontSize: "14px",
        lineHeight: "20px",
      }}
      className="tips-question"
    >
      {props.children}
    </div>
  );
};

export default function Tips(props: { title: string; color?: string }) {
  return (
    <Tooltip
      title={<Title>{props.title}</Title>}
      arrow
      placement="top"
      style={{ cursor: "pointer" }}
    >
      <Stack justifyContent="start" alignContent="center" direction={"row"}>
        <Tootip color={props.color} />
      </Stack>
    </Tooltip>
  );
}
