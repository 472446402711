import { styled } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";

export const Head = styled(Typography)`
  color: #fff;
  font-family: Arboria-Medium;
  font-size: 64px;
  font-weight: 400;
  text-align: center;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "36px",
    },
  })};
`;

export const SubHead = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Arboria-Book;
  font-size: 16px;
  font-weight: 400;
  max-width: 730px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  })};
`;

