import ArchNetwork from "assets/images/eco/ArchNetwork.jpeg";
import AvalonFinance from "assets/images/eco/AvalonFinance.jpeg";
import B2network from "assets/images/eco/B2network.jpeg";
import Bedrock from "assets/images/eco/Bedrock.jpeg";
import BEVM from "assets/images/eco/BEVM.jpeg";
import bitcow from "assets/images/eco/bitcow.jpeg";
import Bitlayer from "assets/images/eco/Bitlayer.jpeg";
import BNBSmartChain from "assets/images/eco/BNBSmartChain.png";
import BoolNetwork from "assets/images/eco/BoolNetwork.jpeg";
import Botanix from "assets/images/eco/Botanix.jpeg";
import BounceBit from "assets/images/eco/BounceBit.jpeg";
import capyfinance from "assets/images/eco/capyfinance.png";
import CoinSummer from "assets/images/eco/CoinSummer.jpeg";
import Core from "assets/images/eco/Core.png";
import Edgevana from "assets/images/eco/Edgevana.png";
import FBTC from "assets/images/eco/FBTC.jpeg";
import GateWeb3 from "assets/images/eco/GateWeb3.jpeg";
import Hashkeycloud from "assets/images/eco/Hashkeycloud.png";
import InfStones from "assets/images/eco/InfStones.jpeg";
import LorenzoProtocol from "assets/images/eco/LorenzoProtocol.jpeg";
import Mantle from "assets/images/eco/Mantle.jpeg";
import MapProtocol from "assets/images/eco/MapProtocol.jpeg";
import MasterProtocol from "assets/images/eco/MasterProtocol.jpeg";
import MathWallet from "assets/images/eco/MathWallet.jpeg";
import MerlinChain from "assets/images/eco/MerlinChain.jpeg";
import OpenBit from "assets/images/eco/OpenBit.png";
import restake from "assets/images/eco/restake.jpeg";
import RunesChain from "assets/images/eco/RunesChain.jpeg";
import SolvFinance from "assets/images/eco/SolvFinance.jpeg";
import TunaChain from "assets/images/eco/TunaChain.jpeg";
import UniRouter from "assets/images/eco/UniRouter.jpeg";
import XLink from "assets/images/eco/XLink.png";
import zearn from "assets/images/eco/zearn.jpeg";
import ZkMe from "assets/images/eco/ZkMe.jpeg";
import Bracle from "assets/images/eco/Bracle.png";
import RockX from "assets/images/eco/RockX.png";
import BOB from "assets/images/eco/BOB.png";
import Chainbase from "assets/images/eco/chainbase.jpeg";
import Staketab from "assets/images/eco/Staketab.webp";
import AILayer from "assets/images/eco/AILayer.jpeg";
import BitSmiley from "assets/images/eco/BitSmiley.jpeg";
import Bit from "assets/images/eco/bit.png";
import Bitfinity from "assets/images/eco/Bitfinity.jpeg";
import DAIC from "assets/images/eco/DAIC.jpeg";
import BIMA from "assets/images/eco/BIMA.jpeg";
import Arbitrum from "assets/images/eco/Arbitrum.png";


export default {
  ArchNetwork,
  AvalonFinance,
  B2network,
  Bedrock,
  BEVM,
  bitcow,
  Bitlayer,
  BNBSmartChain,
  BoolNetwork,
  Botanix,
  BounceBit,
  capyfinance,
  CoinSummer,
  Core,
  Edgevana,
  FBTC,
  GateWeb3,
  Hashkeycloud,
  InfStones,
  LorenzoProtocol,
  Mantle,
  MapProtocol,
  MasterProtocol,
  MathWallet,
  MerlinChain,
  OpenBit,
  restake,
  RunesChain,
  SolvFinance,
  TunaChain,
  UniRouter,
  XLink,
  zearn,
  ZkMe,
  Bracle,
  RockX,
  BOB,
  Chainbase,
  Staketab,
  AILayer,
  BitSmiley,
  Bit,
  Bitfinity,
  DAIC,
  BIMA,
  Arbitrum,
};