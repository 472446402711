import { styled } from "@mui/material/styles";
import { Stack, Typography, Box, Button } from "@mui/material";
import EcoBg from "assets/images/v2/eco-bg.svg";
import Transitions from "components/Transitions";
import { useShow } from "hooks/useShow";

const BanBox = styled(Stack)`
  width: 100%;
  height: 62vh;
  background: #000;
  background-image: url(${EcoBg});
  background-position: right -30% top 30%;
  background-repeat: no-repeat;
  background-size: 65%;
  position: relative;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      height: "70vh",
      backgroundSize: "120%",
      backgroundPosition: "bottom center",
    },
  })};
`;

const BannerButton = styled(Button)`
  color: #fff;
  background: #4c67ef;
  height: 68px;
  padding: 20px 75px;
  &:hover {
    color: #fff;
    background: #4c67ef;
  }
  &.Mui-disabled {
    color: #5d5d5d;
    background-color: #3f3f3f;
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "45px",
      fontSize: "14px",
      padding: "15px 30px",
    },
  })};
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  })};
  @media screen and (min-width: 2000px) {
    width: 80%;
  }
`;

const PcBox = styled(Stack)`
  flex-direction: row;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      padding: "100px 20px 80px 20px",
    },
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      padding: "100px 20px 80px 20px",
    },
  })};
`;

const TextBox = styled(Box)`
  ${({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  })};
  @media screen and (min-width: 2000px) {
    width: 30vw;
  }
`;

const ImgBox = styled(Box)`
  width: 730px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "20px",
    },
  })};
  @media screen and (min-width: 2000px) {
    width: 30vw;
  }
`;

const ImgCon = styled("img")`
  width: 100%;
  height: auto;
`;

const HeadPc = styled(Typography)`
  font-family: Arboria-Bold;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px;
  background: linear-gradient(90deg, #fff 53.31%, #fff 109.42%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "48px",
      lineHeight: "46px",
    },
  })};
  @media screen and (min-width: 2000px) {
    font-size: 3.5vw;
  }
`;

const DescPc = styled(Typography)`
  color: #fff;
  font-family: Arboria-Book;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "30px",
      fontSize: "14px",
      lineHeight: "18px",
    },
  })};
  @media screen and (min-width: 2000px) {
    font-size: 1vw;
  }
`;

const Banner = () => {
  const { ref, isShow } = useShow();
  return (
    <BanBox
      ref={ref}
      justifyContent="center"
      alignItems="center"
      direction="row"
    >
      <Containter>
        <PcBox
          justifyContent="space-between"
          alignItems="center"
          height={"100%"}
        >
          <TextBox>
            <Box>
              <Transitions delay={200} isShow={isShow}>
                <HeadPc>Pell Ecosystem</HeadPc>
              </Transitions>
            </Box>
            <Box>
              <Transitions delay={400} isShow={isShow}>
                <DescPc mt={20}>
                  Zero to One systems are being developed on Pell every day.
                  Discover and explore cutting-edge web services, networks,
                  protocols, and applications being built within the Pell
                  ecosystem.
                </DescPc>
              </Transitions>
            </Box>
            <Box mt={60}>
              <Transitions delay={600} isShow={isShow}>
                <a href="https://forms.gle/yFd2Ys78HniFGEX76" target="_blank">
                  <BannerButton variant="contained">Build on Pell</BannerButton>
                </a>
              </Transitions>
            </Box>
          </TextBox>
          <ImgBox>{/* <ImgCon src={EcoBanner}></ImgCon> */}</ImgBox>
        </PcBox>
      </Containter>
    </BanBox>
  );
};

export default Banner;
