import { styled } from "@mui/material/styles";
import { Stack, Typography, Box } from "@mui/material";
import workImg from "assets/images/v2/workflow.png";
import { Stakers, Operators, Developers } from "components/Svg";
import { Head, SubHead } from "./Style";
import { useShow } from "hooks/useShow";
import Transitions from "components/Transitions";

const Wapper = styled(Stack)`
  /* background: #151515; */
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  padding: 130px 25px 100px;
  box-sizing: border-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "40px 20px 60px",
    },
  })};
`;

const ContentBox = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: 90px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
      flexDirection: "column-reverse",
      paddingBottom: "50px",
      alignItems: "center",
    },
  })};
`;

const ImgCon = styled("img")`
  width: 675px;
  height: auto;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "auto",
    },
  })};
`;

const InfoWapper = styled(Stack)`
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
`;

const InfoBox = styled(Box)`
  width: 400px;
  position: relative;
  z-index: 2;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  })};
`;

const InfoText = styled(Typography)`
  color: #fff;
  font-family: Arboria-Book;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
  })};
`;

const ItemWapper = styled(Box)`
  padding-top: 40px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      padding: "40px 0px",
    },
  })};
`;

const ItemBox = styled(Box)`
  margin-bottom: 70px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      // margin: "80px",
    },
  })};
`;

const ItemTitleBox = styled(Box)`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: #fff;
  font-family: Arboria-Bold;
  font-size: 24px;
  gap: 10px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginBottom: "10px",
    },
  })};
`;

const Workflow = () => {
  const { ref, isShow } = useShow();
  return (
    <Wapper justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Stack justifyContent="center" alignItems="center" direction="row">
          <Transitions delay={200} isShow={isShow}>
            <Head variant="h1">Workflow</Head>
          </Transitions>
        </Stack>
        <Stack
          ref={ref}
          mt={20}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Transitions delay={400} isShow={isShow}>
            <SubHead variant="subtitle1">
              Pell is a meticulously designed multi-layered restaking network
              that offers an excellent user experience, clear role division, and
              a closed-loop business logic chain.
            </SubHead>
          </Transitions>
        </Stack>
        <Transitions isUp={false} delay={600} isShow={isShow}>
          <ContentBox>
            <Stack
              justifyContent="center"
              alignItems={"flex-start"}
              direction="row"
            >
              <ImgCon src={workImg} alt="Workflow" />
            </Stack>
            <InfoWapper
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems={"flex-start"}
              direction="row"
            >
              <InfoBox>
                <ItemWapper>
                  <ItemBox>
                    <ItemTitleBox>
                      <Stakers />
                      Stakers
                    </ItemTitleBox>
                    <InfoText>
                      Stakers can participate in the Pell Network by staking BTC
                      LSD.
                    </InfoText>
                  </ItemBox>
                  <ItemBox>
                    <ItemTitleBox>
                      <Operators />
                      Operators
                    </ItemTitleBox>
                    <InfoText>
                      Node operators run AVSs on Pell Network to secure more
                      network‘s ecosystem with BTC LSD.
                    </InfoText>
                  </ItemBox>
                  <ItemBox>
                    <ItemTitleBox>
                      <Developers />
                      Developers
                    </ItemTitleBox>
                    <InfoText>
                      AVS developers customize slashing and rewards rules for
                      distributed systems.
                    </InfoText>
                  </ItemBox>
                </ItemWapper>
              </InfoBox>
            </InfoWapper>
          </ContentBox>
        </Transitions>
      </Containter>
    </Wapper>
  );
};

export default Workflow;
