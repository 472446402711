import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const Logo: React.FC<IconProps> = ({ width = "102px", height = "36px" }) => {
  return (
    <SvgIcon style={{ width, height }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="35" viewBox="0 0 100 35" fill="none">
        <path d="M25.6962 5.17518L25.6952 0L22.2168 3.82216C22.1758 3.86733 20.5326 5.67202 18.4412 7.97154C18.3091 7.38336 18.1189 6.80321 17.8708 6.23912C16.8651 3.95064 15.0308 2.19212 12.7062 1.28777C10.3816 0.383421 7.84479 0.440633 5.56321 1.45037C3.28162 2.45911 1.5284 4.29893 0.62677 6.63057C0.0903963 8.0167 0.0133426 9.3647 0.00933983 10.5009C-0.00266852 13.5392 0.000333565 28.8087 0.000333565 28.9623L0.00133426 34.1375L3.47976 30.3153C3.52078 30.2702 5.16393 28.4655 7.25538 26.166C7.38747 26.7541 7.57761 27.3343 7.82578 27.8984C8.83148 30.1869 10.6668 31.9454 12.9914 32.8497C14.0871 33.2763 15.2289 33.4891 16.3707 33.4881C17.6506 33.4881 18.9285 33.2201 20.1343 32.6871C22.4159 31.6784 24.1692 29.8386 25.0708 27.5069C25.6072 26.1208 25.6832 24.7718 25.6882 23.6356C25.6992 20.5983 25.6962 5.32874 25.6962 5.17518ZM4.01213 10.516C4.01513 9.70897 4.05916 8.85581 4.35837 8.08195C4.87372 6.75001 5.87442 5.69912 7.17733 5.12298C7.86681 4.81785 8.59632 4.66529 9.32682 4.66529C9.97828 4.66529 10.6307 4.78674 11.2572 5.03064C12.5851 5.54756 13.6328 6.55128 14.2072 7.85912C14.7816 9.16596 14.8146 10.6193 14.2993 11.9513C13.9971 12.7322 13.4327 13.4157 12.9203 13.9808C11.7005 15.3268 7.22536 20.244 4.00212 23.7871C4.00312 19.0165 4.00512 12.4009 4.01213 10.516ZM21.6844 23.6215C21.6814 24.4285 21.6374 25.2817 21.3382 26.0555C20.8228 27.3875 19.8221 28.4384 18.5192 29.0145C17.2163 29.5906 15.7673 29.6238 14.4394 29.1068C13.1115 28.5899 12.0637 27.5862 11.4893 26.2784C10.9149 24.9715 10.8819 23.5181 11.3973 22.1862C11.6995 21.4053 12.2639 20.7218 12.7762 20.1567C13.9961 18.8107 18.4712 13.8935 21.6944 10.3504C21.6934 15.121 21.6924 21.7365 21.6844 23.6215Z" fill="white"/>
        <path d="M55.9135 12.953C55.9135 16.4633 53.0568 19.3191 49.5465 19.3191H43.7199C42.696 19.3191 41.8634 20.1526 41.8634 21.1756V27.5154H38.4961V21.1756C38.4961 18.2952 40.8395 15.9518 43.7199 15.9518H49.5465C51.2003 15.9518 52.5462 14.6068 52.5462 12.953C52.5462 11.2983 51.2003 9.95326 49.5465 9.95326H38.4961V6.58594H49.5465C53.0568 6.58594 55.9135 9.44175 55.9135 12.953Z" fill="white"/>
        <path d="M61.6798 11.8334V15.6193H72.2169V18.5312H61.6798V22.267C61.6798 23.3041 62.523 24.1481 63.56 24.1481H72.8021V27.5154H63.56C60.6665 27.5154 58.3125 25.1606 58.3125 22.267V11.8334C58.3125 8.9399 60.6665 6.58594 63.56 6.58594H72.8021V9.95326H63.56C62.523 9.95326 61.6798 10.7964 61.6798 11.8334Z" fill="white"/>
        <path d="M86.7666 24.1481V27.5154H80.9216C78.0913 27.5154 75.7891 25.2124 75.7891 22.3829V6.58594H79.1564V22.3829C79.1564 23.3559 79.9486 24.1481 80.9216 24.1481H86.7666Z" fill="white"/>
        <path d="M100.001 24.1481V27.5154H94.156C91.3256 27.5154 89.0234 25.2124 89.0234 22.3829V6.58594H92.3908V22.3829C92.3908 23.3559 93.183 24.1481 94.156 24.1481H100.001Z" fill="white"/>
      </svg>
    </SvgIcon>
  );
};

export default Logo;
