import { styled } from "@mui/material/styles";
import { Stack, Typography, Box, Button } from "@mui/material";
import ecoImg from "assets/images/v2/eco.png";
import { Head, SubHead } from "./Style";
import { useShow } from "hooks/useShow";
import Transitions from "components/Transitions";

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  padding: 100px 25px 200px;
  box-sizing: border-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "40px 20px 60px",
    },
  })};
`;

const ImgBox = styled(Stack)`
  margin-top: 160px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginTop: "72px",
    },
  })};
`;

const ImgCon = styled("img")`
  width: 980px;
  height: auto;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "auto",
    },
  })};
`;

const EcosystemSynergy = () => {
  const { ref, isShow } = useShow();
  return (
    <Stack justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Stack justifyContent="center" alignItems="center" direction="row">
          <Transitions delay={200} isShow={isShow}>
            <Head variant="h1">Ecosystem Synergy</Head>
          </Transitions>
        </Stack>
        <Stack
          mt={20}
          ref={ref}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Transitions delay={400} isShow={isShow}>
            <SubHead variant="subtitle1">
              Promote ecosystem development, reduce dev cost for builders to let
              them focus on business logic rather than security infra.
            </SubHead>
          </Transitions>
        </Stack>
        <Stack
          mt={15}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Transitions delay={400} isShow={isShow}>
            <SubHead variant="subtitle1">
              Improve ecosystem diversity and security, speed up implementation
              of new technology, supported by cryptonomic security.
            </SubHead>
          </Transitions>
        </Stack>
        <Transitions isUp={false} delay={600} isShow={isShow}>
          <ImgBox justifyContent="center" alignItems="center" direction="row">
            <ImgCon src={ecoImg} alt="" />
          </ImgBox>
        </Transitions>
      </Containter>
    </Stack>
  );
};

export default EcosystemSynergy;
