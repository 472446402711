import { useEffect, useState, useRef, useCallback } from 'react'
import { useInView } from "react-intersection-observer";

export const useShow = () => {
  const { ref, inView } = useInView({});
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    if (inView === true) {
      setIsShow(true);
    }
  }, [inView]);
	return {
    ref,
		isShow
	}
};