import request from "utils/request";

export enum StakeTokenType {
  DEFAULT = 1,
  PLEDGE = 2,
  STCLAIM = 3,
}

export interface StrategyItem {
  id: string
  chainId: number
  stakeName: string
  stakeDesc: string
  stakeIcon: string
  currency: string
  underlineAddress: `0x${string}` | undefined
  strategyAddress: `0x${string}` | undefined
  decimals: number
  pointsBase: string
  restakedAmount: string
  restakedPoints: string
  tvl: string
  withdrawalDelay: number
  stakeType: StakeTokenType
  poolAddress: `0x${string}` | undefined
  originalUnderlineAddress: `0x${string}` | undefined
  pointDesc: string
  baseBoost: number
}

export const getTvl = () => {
  return request<{tvl: number, count: number}>({
    url: `/tvl`,
    method: "GET",
  });
}

export const getStakeList = () => {
  return request<StrategyItem[]>({
    url: `/stakeList`,
    method: "GET",
  });
}