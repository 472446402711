import { IconProps } from "../type";

const Tootip: React.FC<IconProps> = ({
  width = "16px",
  height = "16px",
  color = "#999999",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={{ width: width, height: height }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1141 0.628293C10.1268 0.210732 9.07903 -6.05051e-07 8 -6.99382e-07C6.92098 -7.93714e-07 5.87317 0.210732 4.88586 0.628292C3.93366 1.03219 3.07707 1.60975 2.34342 2.34341C1.60781 3.07707 1.0322 3.93366 0.628294 4.88585C0.210733 5.87317 7.93714e-07 6.92097 6.99382e-07 8C6.05051e-07 9.07902 0.210733 10.1268 0.628293 11.1141C1.0322 12.0663 1.60976 12.9229 2.34341 13.6566C3.07707 14.3922 3.93366 14.9678 4.88585 15.3717C5.87317 15.7893 6.92098 16 8 16C9.07902 16 10.1268 15.7893 11.1141 15.3717C12.0663 14.9678 12.9229 14.3902 13.6566 13.6566C14.3922 12.9229 14.9678 12.0663 15.3717 11.1141C15.7893 10.1268 16 9.07902 16 8C16 6.92098 15.7893 5.87317 15.3717 4.88585C14.9678 3.93366 14.3902 3.07707 13.6566 2.34341C12.9229 1.6078 12.0663 1.03219 11.1141 0.628293ZM14.8 7.99999C14.8 11.75 11.75 14.8 8.00005 14.8C4.25005 14.8 1.20005 11.75 1.20005 7.99999C1.20005 4.24999 4.25005 1.19999 8.00005 1.19999C11.75 1.19999 14.8 4.24999 14.8 7.99999ZM7.99969 6.62834C8.37628 6.62834 8.68262 6.93468 8.68262 7.31127L8.68262 11.6157C8.68262 11.9922 8.37628 12.2986 7.99969 12.2986C7.6231 12.2986 7.31676 11.9922 7.31676 11.6157L7.31676 7.31127C7.31676 6.93468 7.6231 6.62834 7.99969 6.62834ZM7.8158 4.4083C8.06092 4.34263 8.32245 4.41272 8.50187 4.59216C8.68132 4.77158 8.75142 5.03311 8.68575 5.27822C8.62008 5.52334 8.42863 5.7148 8.18352 5.78047C7.93841 5.84615 7.67688 5.77606 7.49745 5.59662C7.318 5.4172 7.2479 5.15567 7.31357 4.91055C7.37924 4.66544 7.57069 4.47398 7.8158 4.4083Z"
        fill={color}
      />
    </svg>
  );
};

export default Tootip;
