import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const Website: React.FC<IconProps> = ({ width = "12px", height = "12px" }) => {
  return (
    <SvgIcon style={{ width, height }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        className="website-svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.50652 0.493741L10.0106 0.493741C10.287 0.493741 10.5111 0.717803 10.5111 0.994197V5.49831C10.5111 5.7747 10.287 5.99876 10.0106 5.99876C9.73424 5.99876 9.51018 5.7747 9.51018 5.49831V2.20241L1.35629 10.3563L0.648539 9.64854L8.80242 1.49465L5.50652 1.49465C5.23013 1.49465 5.00607 1.27059 5.00607 0.994197C5.00607 0.717803 5.23013 0.493741 5.50652 0.493741Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default Website;
