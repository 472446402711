import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import Banner from "./components/Banner";
import Networks from "./components/Networks";
import Workflow from "./components/Workflow";
import KeyComponents from "./components/KeyComponents";
import AboutPellEcosystem from "./components/AboutPellEcosystem";
import EcosystemSynergy from "./components/EcosystemSynergy";
import pcBg from "assets/images/v2/pc-bg.svg";
import pcBottomBg from "assets/images/v2/pc-bottom-bg.svg";

const Containter = styled(Stack)`
  .parallax__group {
    position: relative;
    transform-origin: center top;
    transform: scaleY(calc(1 - (0.5)));
  }

  .parallax__layer {
    transform-origin: center top;
    transform: scaleY(calc(1 / (1 - (0.5))));
  }

  .parallax__layer--foreground {
    margin-top: -2300px;
    position: relative;
    top: 0px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-image: url(${pcBottomBg});
    background-position: bottom 650px center;
  }

  .parallax__layer--background {
    position: sticky;
    z-index: -2;
    top: 0px;
    height: 100%;
    min-height: 2300px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top -500px center;
    background-image: url(${pcBg});

    ${({ theme }) => ({
      [theme.breakpoints.down("md")]: {
        backgroundPosition: "top 0 center",
      },
    })};
  }
`;

// const BgBox = styled("div")`
//   position: relative;
//   z-index: 1;
//   overflow: hidden;
// `;

// const BgCon = styled("img")`
//   position: absolute;
//   width: 100%;
//   height: auto;
//   z-index: -1;
//   top: 0;
// `;

const Home = () => {
  return (
    <Containter>
      <Banner />
      <section className="parallax__group">
        <div className="parallax__layer parallax__layer--background"></div>
        <div className="parallax__layer parallax__layer--foreground">
          <Networks />
          <Workflow />
          <KeyComponents />
          <AboutPellEcosystem />
          <EcosystemSynergy />
        </div>
      </section>
    </Containter>
  );
};

export default Home;
