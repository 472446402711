import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const Medium: React.FC<IconProps> = ({ width = "20px", height = "20px" }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      className="medium-svg"
      viewBox="0 0 20 18"
      fill="none"
      style={{ fill: "none", width, height }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="8"
        viewBox="0 0 15 8"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.99932 4C7.99932 6.20903 6.20868 8 3.99932 8C1.79063 8 0 6.20903 0 4C0 1.79063 1.79063 0 3.99932 0C6.20868 0 7.99932 1.79063 7.99932 4Z"
          fill="#A9A9A9"
          className="fill-color"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.302 3.99977C12.302 6.0856 11.4133 7.77658 10.3175 7.77658C9.22167 7.77658 8.33301 6.0856 8.33301 3.99977C8.33301 1.91394 9.22167 0.222961 10.3175 0.222961C11.4133 0.222961 12.302 1.91394 12.302 3.99977Z"
          fill="#A9A9A9"
          className="fill-color"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.1091 3.99991C14.1091 5.86119 13.7928 7.37027 13.4031 7.37027C13.0126 7.37027 12.6963 5.86119 12.6963 3.99991C12.6963 2.1383 13.0126 0.629211 13.4031 0.629211C13.7928 0.629211 14.1091 2.1383 14.1091 3.99991Z"
          fill="#A9A9A9"
          className="fill-color"
        />
      </svg>
    </SvgIcon>
  );
};

export default Medium;
